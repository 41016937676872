<template>
  <div>
    <fw-panel title="Edições" :counter="editions.length" :counter-total="totalResults" class="my-5">
      <LoadingPlaceholder v-if="loading" />
      <fw-panel-info v-else-if="!loading && !editions.length" empty>
        <p>Ainda não foram criadas edições neste curso.</p>
        <fw-button type="primary" class="mt-3" @click.native="$emit('new-edition')">Criar edição</fw-button>
      </fw-panel-info>
      <div
        v-else-if="!loading && editions.length > 0"
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-4"
      >
        <CardManageCourseEdition
          v-for="item in editions"
          :key="item.key"
          :item="item"
          :users="users"
          :validations="validations?.[item.key] || {}"
          :enrollments="enrollments?.[item.key] || []"
          :stats="stats?.[item.key] || {}"
          @open="goToEnrollments($event)"
          @edit="$emit('edit', $event)"
          @delete="$emit('delete', $event)"
          @publish="$emit('publish', $event)"
        />
        <div class="p-10 rounded-xl flex justify-center items-center border-2 border-dashed border-gray-300">
          <fw-button type="xlight" @click.native="$emit('new-edition')">Criar nova edição</fw-button>
        </div>
      </div>
      <BlockPagination
        v-if="totalPages > 1 && !loading"
        :per-page="limit"
        :total="totalResults"
        :total-pages="totalPages"
        :current.sync="page"
        @page-changed="pageChanged"
      />
    </fw-panel>
  </div>
</template>

<script>
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import CardManageCourseEdition from '@/components/cards/manage/CardManageCourseEdition'
// import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: {
    BlockPagination,
    LoadingPlaceholder,
    CardManageCourseEdition,
  },
  props: {
    courseKey: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      filterByType: 'all',
      orderBy: [
        {
          key: 'title',
          label: 'Nome',
          type: 'string',
        },
      ],
      searchInput: '',
      orderByValue: 'title',
      orderDirection: 'ASC',
      page: 1,
      totalResults: 0,
      totalPages: 1,
      limit: 100,
      editions: [],
      users: {},
      stats: {},
      loading: true,
      activeModal: null,
      validations: {},
      enrollments: {},
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },

    api() {
      return this.$store.state.api.base
    },
  },

  mounted() {
    this.getUrlParams()
    this.getEditions()
  },

  methods: {
    goToEnrollments(item) {
      this.$router.push({
        name: 'manage-course-edition-enrollments',
        params: { key: this.courseKey, editionKey: item.key },
      })
    },
    search(data) {
      console.log('search', data)
      this.searchInput = data.term

      if (data.orderBy != null) {
        this.orderByValue = data.orderBy
        this.orderDirection = data.orderDirection
      }

      this.$emit('searching')

      this.setUrlParams()
      this.getEditions()
    },

    getUrlParams() {
      if (this.$route.query.q) {
        this.searchInput = this.$route.query.q
      }

      if (this.$route.query.s) {
        this.orderByValue = this.$route.query.s
        this.orderDirection =
          this.$route.query.o == 'ASC' || this.$route.query.o == 'DESC' ? this.$route.query.o : 'none'
      }

      if (this.$route.query.p) {
        this.page = parseInt(this.$route.query.p)
      }
    },

    setUrlParams() {
      let query = {}
      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.orderByValue.length > 0) {
        query['s'] = this.orderByValue
        query['o'] = this.orderDirection
      }

      query['p'] = this.page

      this.$router.push({ path: this.$route.path, query: query })
    },

    sortOrderChanged(newSort) {
      if (newSort != null && newSort.key != null) {
        this.orderByValue = newSort.key.key
        this.orderDirection = newSort.direction
      }
      this.setUrlParams()
      this.getEditions()
    },

    async getEditions() {
      this.loading = true
      const query = {
        limit: this.limit,
        page: this.page,
      }
      console.log('query', query)
      if (this.orderByValue.length > 0) {
        query['sort'] = this.orderByValue
        query['direction'] = this.orderDirection.toLowerCase()
      }

      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      try {
        const result = await this.api.getManagerEditions(this.courseKey, query)
        console.log('getEditions :>> ', result)
        this.totalResults = result.pagination?.total_items
        this.totalPages = result.pagination?.total_pages
        this.page = result.pagination?.current_page
        this.editions = result.editions
        this.enrollments = result.enrollments
        this.validations = result.validations
        this.stats = result.stats
        this.users = { ...this.users, ...result.users }
      } catch (error) {
        console.error('getEditions Error: ', error)
      }

      this.loading = false
    },

    pageChanged(page) {
      console.log('pageChanged to :>> ', page)
      if (page) this.page = page
      this.setUrlParams()
      this.getEditions()
    },

    parseDatesForPayload(formatedDate) {
      if (formatedDate) {
        return Dates.from(formatedDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
      return formatedDate
    },
  },
}
</script>
