<template>
  <fw-panel
    v-if="edition"
    :title="edition.key ? 'Editar edição' : 'Adicionar edição'"
    :loading="savingData"
    after-loading-checked
  >
    <div>
      <fw-label>{{ $t('title.label') }}</fw-label>
      <div v-if="canEdit">
        <TextInput
          v-model="tmpEdition.title"
          :minlength="1"
          :maxlength="250"
          :placeholder="$t('title.placeholder')"
          :class="{
            error: $v.tmpEdition.title.$error,
          }"
        />
        <fw-tip v-if="$v.tmpEdition.title.$error" error>
          <span v-if="!$v.tmpEdition.title.required">{{ $t('title.required') }}</span>
          <span v-else>{{ $t('title.invalid') }}</span>
        </fw-tip>
      </div>
      <fw-panel-info v-else clean>{{ tmpEdition.title }}</fw-panel-info>
    </div>

    <div class="flex-1 flex justify-end items-center mt-3">
      <fw-button type="link-muted" class="mr-4" @click.native="$emit('close')">Cancelar</fw-button>
      <fw-button type="primary" :loading="savingData" :disabled="savingData" @click.native="saveEdition">{{
        $t('save')
      }}</fw-button>
    </div>
  </fw-panel>
</template>

<script>
import TextInput from '@/fw-modules/fw-core-vue/ui/components/form/TextInput'
import { required, maxLength, minLength } from 'vuelidate/lib/validators'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: {
    TextInput,
  },

  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    savingData: {
      type: Boolean,
      default: false,
    },
    edition: {
      type: Object,
      default: () => {
        return {
          key: null,
          title: null,
        }
      },
    },
  },

  validations: {
    tmpEdition: {
      title: { required, min: minLength(2), max: maxLength(250) },
    },
  },

  created() {
    this.tmpEdition = { ...this.edition }
  },

  methods: {
    formatDate(value) {
      return Dates.buildCore(value)
        .tz('Europe/Lisbon')
        .format('YYYY-MM-DD')
    },

    saveEdition() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.$emit('save', { ...this.tmpEdition })
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "charsLimit": "Limite de {limit} caracteres.",
    "thereAreErrors": "Existem erros de formulário",
    "save": "Guardar",
    "close": "Fechar",
    "edit": "Editar",
    "title": {
      "label": "Título",
      "placeholder": "Título",
      "required": "Insira um título",
      "invalid": "Insira um título válido"
    }
  },
  "en": {
    "close": "Close",
    "edit": "Edit",
    "charsLimit": "Limit of {limit} characters.",
    "thereAreErrors": "There are errors in the form",
    "save": "Save",
    "title": {
      "label": "Title",
      "placeholder": "Title",
      "required": "Enter a title",
      "invalid": "Enter a valid title"
    }
  }
}
</i18n>
