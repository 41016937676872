<template>
  <router-link
    :to="{
      name: 'manage-course-edition-enrollments',
      params: { key: item.course_key, editionKey: item.key },
    }"
    class="group flex flex-col gap-1 p-1 rounded-xl transition-all hover:shadow-lg hover:bg-white cursor-pointer overflow-hidden relative bg-gray-200 bg-opacity-80 "
  >
    <div class="relative">
      <div class="px-4 py-3 overflow-hidden rounded-lg">
        <div class="flex gap-3 justify-between items-center opacity-90">
          <div>
            <fw-icon-cube class="w-6 h-6" />
          </div>
          <div>
            <fw-tag v-if="item.published_at">Publicada</fw-tag>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-1 px-4 py-2 flex flex-col justify-end gap-2">
      <div class="flex-1 font-bold uppercase text-lg">
        <v-clamp autoresize :max-lines="3">
          {{ item.title || 'Sem título' }}
        </v-clamp>
      </div>
      <div class="flex flex-col gap-0.5 font-medium text-gray-500 text-sm">
        <div>{{ stats?.enrollments || 0 }} {{ stats?.enrollments == 1 ? 'Certificado' : 'Certificados' }}</div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
    validations: {
      type: Object,
      default: () => {
        return {
          can_delete: true,
        }
      },
    },
    stats: {
      type: Object,
      default: () => {
        return {
          enrollments: 0,
        }
      },
    },
  },
}
</script>
